import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { subscribeToNewsletter } from '../../services/apiService';
import SuggestionsList from '../FindFlightsBlock/SearchForm/components/AirpotInput/SuggestionsList';
import { getThemeName } from '../../helpers/urlParams';

const SubscribeForm = ({ isInnerPage, smallSize = false }) => {
  const dataTheme = getThemeName();
  const [phoneValue, changePhoneValue] = React.useState('');
  const [displaySuccessMessage, changeDisplaySuccessMessage] = React.useState(
    false
  );
  const [displayError, changeDisplayError] = React.useState(false);
  const [phoneNumAgreement, setPhoneNumAgreement] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    if (displayError || phoneValue === '' || !phoneNumAgreement) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [phoneNumAgreement, phoneValue, displayError]);

  const handlePhoneChange = e => {
    const inputValue = e.target.value;
    let formattedValue = inputValue.trim();

    if (formattedValue.length >= 12 && !/[^0-9+]/.test(inputValue)) {
      changeDisplayError(false);
      changePhoneValue(e.target.value);
      return;
    }

    changeDisplayError(true);
    setErrorMessage('Please provide a correct phone number with country code');
    changePhoneValue(e.target.value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    changeDisplaySuccessMessage(!displaySuccessMessage);
    subscribeToNewsletter({
      email: '',
      contactNumber: phoneValue,
      source: 'subscribeToNewsletter'
    });
  };
  return (
    <React.Fragment>
      <div
        className={`${smallSize ? 'small-size' : 'block-wrapper'} d-flex ${
          isInnerPage ? 'inner-page-block-wrapper' : ''
        }`}
      >
        <div className="subscribe-wrapper d-flex" data-theme={dataTheme}>
          <form className="subscribe-form d-flex" data-subscription-form>
            <div className="img-wrapper">
              <div className="subscribe-icon"></div>
            </div>
            <div className="submit-block">
              <h3>Subscribe to our newsletter and save!</h3>
              <p>
                Receive hot deals, secret promos and new articles on
                sms/whatsapp
              </p>

              {!displaySuccessMessage && (
                <>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="consent-checkbox"
                      name="consent-checkbox"
                      onChange={e => setPhoneNumAgreement(e.target.checked)}
                    />
                    <label for="consent-checkbox">
                      I agree to receive SMS from Travelopod
                    </label>
                  </div>

                  <div className="submit-wrapper d-flex">
                    <label>
                      <input
                        className="submit-input"
                        type="tel"
                        value={phoneValue}
                        placeholder="+X XXX XXXX XXXX"
                        onChange={handlePhoneChange}
                      />
                    </label>
                    <div className="btn-submit">
                      <button
                        disabled={disabled}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </>
              )}
              {displayError && (
                <span className="input-error">{errorMessage}</span>
              )}

              {displaySuccessMessage && (
                <div className="submit-block success d-flex">
                  <span>Success</span>
                  <div className="success-icon" />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscribeForm;

SubscribeForm.propTypes = {
  isInnerPage: PropTypes.bool
};

SuggestionsList.defaultProps = {
  isInnerPage: false
};
